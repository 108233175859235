<template>
  <div>
    <b-button id="bid-modal-button" v-b-modal.bid-price-modal class="hidden">Launch scrolling modal</b-button>
    <b-modal id="bid-price-modal" centered @show="$emit('show')" @hidden="$emit('hidden')" @ok="okHandler">
      <template #modal-header="{ close }">
        <button class="close-button ml-auto" @click="close()"><b-icon-x size="xl" /></button>
      </template>
      <b-table-simple responsive class="text-center">
        <b-tbody role="rowgroup">
          <b-tr>
            <b-td class="word-keep-all text-left p-.5 border-0">사용여부</b-td>
            <b-td class="p-.5 border-0">
              <b-form-radio-group v-model="emitData.active" class="text-left">
                <b-form-radio :value="true">사용</b-form-radio>
                <b-form-radio :value="false">일시정지</b-form-radio>
              </b-form-radio-group>
            </b-td>
          </b-tr>
          <hr class="table-hr" />
          <b-tr class="border-top-1">
            <b-td class="word-keep-all text-left p-.5 border-0">디바이스</b-td>
            <b-td class="p-.5 border-0">
              <b-form-radio-group v-model="emitData.gubun" class="text-left">
                <b-form-radio value="PC">
                  <img class="cell-img" src="@/assets/images/custom/pc.png" />
                </b-form-radio>
                <b-form-radio value="MOBILE">
                  <img class="cell-img" src="@/assets/images/custom/mobile.png" />
                </b-form-radio>
                <b-form-radio value="MOmore">
                  <img class="cell-img" src="@/assets/images/custom/mobile_more.png" />
                </b-form-radio>
              </b-form-radio-group>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="word-keep-all text-left p-.5 border-0">목표순위</b-td>
            <b-td class="p-.5 border-0">
              <b-input-group class="gap-.5">
                <b-form-select v-model="emitData.rankNo" class="inner_select">
                  <b-form-select-option v-for="index in 100" :value="index" :key="index">{{ index }}</b-form-select-option>
                </b-form-select>
                <b-button class="w-48 p-.5" size="sm" @click="add('rankNo', 1)">+1</b-button>
                <b-button class="w-48 p-.5" size="sm" @click="add('rankNo', -1)">-1</b-button>
              </b-input-group>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="word-keep-all text-left p-.5 border-0">목표순위2</b-td>
            <b-td class="p-.5 border-0">
              <b-input-group class="gap-.5">
                <b-form-select v-model="emitData.rankNo2" class="inner_select">
                  <b-form-select-option v-for="index in 100" :value="index" :key="index">{{ index }}</b-form-select-option>
                </b-form-select>
                <b-button class="w-48 p-.5" size="sm" @click="add('rankNo2', 1)">+1</b-button>
                <b-button class="w-48 p-.5" size="sm" @click="add('rankNo2', -1)">-1</b-button>
              </b-input-group>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="word-keep-all text-left p-.5 border-0">증가액(+)</b-td>
            <b-td class="p-.5 border-0">
              <b-input-group class="gap-.5">
                <b-form-input v-model="emitData.price" aria-label="Text input with checkbox"></b-form-input>
                <b-button class="w-48 p-.5" size="sm" @click="add('price', 10)">+10</b-button>
                <b-button class="w-48 p-.5" size="sm" @click="add('price', -10)">-10</b-button>
              </b-input-group>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td class="word-keep-all text-left p-.5 border-0">증감액(-)</b-td>
            <b-td class="p-.5 border-0">
              <b-input-group class="gap-.5">
                <b-form-input v-model="emitData.price_minus" aria-label="Text input with checkbox"></b-form-input>
                <b-button class="w-48 p-.5" size="sm" @click="add('price_minus', 10)">+10</b-button>
                <b-button class="w-48 p-.5" size="sm" @click="add('price_minus', -10)">-10</b-button>
              </b-input-group>
            </b-td>
          </b-tr>
          <b-tr class="border-top-1">
            <b-td class="word-keep-all text-left p-.5 border-0">최대입찰가</b-td>
            <b-td class="p-.5 border-0">
              <div class="d-flex gap-.5">
                <b-input-group>
                  <b-form-input v-model="emitData.mobile_maxPrice" aria-label="Text input with checkbox"></b-form-input>
                </b-input-group>
              </div>
              <div class="d-flex justify-content-end gap-.5 mt-1">
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', 1000)">+1000</b-button>
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', -1000)">-1000</b-button>
              </div>
              <div class="d-flex justify-content-end gap-.5 mt-1">
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', 100)">+100</b-button>
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', -100)">-100</b-button>
              </div>
              <div class="d-flex justify-content-end gap-.5 mt-1">
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', 10)">+10</b-button>
                <b-button class="w-full p-.5" size="sm" @click="add('mobile_maxPrice', -10)">-10</b-button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <template #modal-footer="{ ok, cancel, hide }">
        <div class="d-flex gap-.5">
          <b-button class="w-full" size="sm" @click="cancel()"> Cancel </b-button>
          <b-button class="w-full" variant="primary" size="sm" @click="confirm(ok)"> OK </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
  props: ['selectedTableData'],
  components: {},
  data() {
    return {
      emitData: {
        active: false,
        gubun: 'PC',
        rankNo: 1,
        rankNo2: 1,
        price: 0,
        price_minus: 0,
        mobile_maxPrice: 0,
        keywordList_seq: undefined
      }
    }
  },
  computed: {},
  methods: {
    ...mapMutations('common', ['SET_COMMON_MODAL']),
    okHandler() {
      const emitData = this.emitData
      emitData.active = emitData.active ? 'Y' : 'N'
      emitData.rankNo = Number(emitData.rankNo)
      emitData.rankNo2 = Number(emitData.rankNo2)
      this.$emit('ok', emitData)
    },
    add(key, value) {
      this.emitData[key] = Number(this.emitData[key]) + value
      if (this.emitData[key] < 0) this.emitData[key] = 1
    },
    confirm(ok) {
      this.SET_COMMON_MODAL({ type: 'confirm', message: '수정하시겠습니까?', callbackFn: () => ok()})
    }
  },
  mounted() {},
  watch: {
    selectedTableData: {
      handler(data) {
        if (!data) return
        this.emitData = {
          active: data['Active'] === 'Y',
          gubun: data['WebMobileGubun'],
          rankNo: data['RankNo'],
          rankNo2: data['RankNo2'],
          price: data['Price'],
          price_minus: data['Price_Minus'],
          mobile_maxPrice: data['Mobile_MaxPrice'],
          keywordList_seq: data['KeywordList_SEQ']
        }
      }
    }
  }
}
</script>
<style lang="scss">
#bid-price-modal {
  .close-button {
    font-size: 2rem;
    padding-right: .5rem;
    color: #5e5873;
  }
}
</style>

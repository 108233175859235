<template>
  <div class="bid-prices">
    <div class="search-filter-form">
      <div class="filter-area d-flex">
        <div class="select mt-0">
          <b-form-select :value="selectedProject" class="inner_select" @change="selectOption">
            <b-form-select-option v-for="option in projectBodies" :key="option.Seq" :value="option.Seq" :label="option.ProjectNM"></b-form-select-option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="card mt-1 mb-0">
      <b-table hover :fields="tableHeads" :items="tableRows" responsive :class="{ nodata: displayClass === 'nodata', load_ing: displayClass === 'loading' }" class="adjust-table text-center" @row-clicked="showModal($event[6].data)">
        <template v-slot:head(0)="data">
          <span class="word-keep-all">
            기기<br class="d-lg-none" />
            <span class="d-none d-md-inline">/</span>상태
          </span>
        </template>

        <template v-slot:cell(0)="{ value }">
          <div class="d-flex flex-column flex-md-row gap-.5 justify-content-center">
            <img class="cell-img" v-if="value['WebMobileGubun'] === 'PC'" src="@/assets/images/custom/pc.png" />
            <img class="cell-img" v-else-if="value['WebMobileGubun'] === 'MOBILE'" src="@/assets/images/custom/mobile.png" />
            <img class="cell-img" v-else src="@/assets/images/custom/mobile_more.png" />
            <span class="d-none d-md-inline"> / </span>
            <img v-if="value['Active'] === 'Y'" class="cell-img" src="@/assets/images/custom/media_play.png" />
            <img v-else class="cell-img" src="@/assets/images/custom/media_stop_red.png" />
          </div>
        </template>
        <template v-slot:cell(1)="{ value }">
          <div class="min-w-40">{{ value['Keyword'] }}</div>
        </template>
        <template v-slot:cell(2)="{ value }">{{ value['RankNo'] }}</template>
        <template v-slot:cell(3)="{ value }">{{ formatCurrency(value['Mobile_MaxPrice']) }}</template>
        <template v-slot:cell(4)="{ value }">{{ value['Price'] }}</template>
        <template v-slot:cell(5)="{ value }">{{ value['Price_Minus'] }}</template>
      </b-table>

      <BidPricesModal :selectedTableData="selectedTableData" @ok="okHandler" @hidden="emptySelectedTableData" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { formatCurrency } from '@/utils/StringUtils'
import BidPricesModal from '@/views/components/BidPricesModal.vue'

export default {
  components: {
    BidPricesModal
  },
  data() {
    return {
      selectedProject: undefined,
      list: undefined,
      selectedTableData: undefined
    }
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('filterParam', ['params']),
    ...mapGetters('bid', ['projectBodies']),
    displayClass() {
      if (this.loading && !this.list) {
        return 'loading'
      } else if (!this.loading && this.tableRows && this.tableRows.length === 0) {
        return 'nodata'
      } else {
        return ''
      }
    },
    tableHeads() {
      return [
        { key: '0', label: '사용여부', class: 'cell-padding text-center' },
        { key: '1', label: '키워드', class: 'cell-padding text-center' },
        { key: '2', label: '목표', class: 'cell-padding text-center' },
        { key: '3', label: '제한액', class: 'cell-padding text-center' },
        { key: '4', label: '증가액', class: 'cell-padding text-center' },
        { key: '5', label: '증감액', class: 'cell-padding text-center' }
      ]
    },
    tableRows() {
      return (this.list?.Body || []).map(item => {
        const obj = {
          0: {
            Active: item['Active'],
            class: 'cell-padding text-center',
            WebMobileGubun: item['WebMobileGubun']
          },
          1: {
            Keyword: item['Keyword'],
            class: 'cell-padding text-center'
          },
          2: {
            RankNo: item['RankNo'],
            class: 'cell-padding text-center'
          },
          3: {
            Mobile_MaxPrice: item['Mobile_MaxPrice'],
            class: 'cell-padding text-center'
          },
          4: {
            Price: item['Price'],
            class: 'cell-padding text-center'
          },
          5: {
            Price_Minus: item['Price_Minus'],
            class: 'cell-padding text-center'
          },
          6: {
            data: item,
            class: 'cell-padding text-center'
          }
        }
        return obj
      })
    }
  },
  methods: {
    ...mapActions('bid', ['fetchProjects', 'fetchMainList', 'fetchBiddingPriceList', 'updateBiddingPriceList']),
    ...mapMutations('bid', ['SELECT_LOG_KEYWORD']),
    ...mapMutations('common', ['SET_LOADING', 'SET_COMMON_MODAL']),

    formatCurrency,

    selectOption(value) {
      if (this.selectedProject === value) return
      this.selectedProject = value

      this.search()
    },
    showLog(nccKeywordidId) {
      this.SELECT_LOG_KEYWORD({ project: this.selectedProject, nccKeywordidId })
      this.$router.push('/bidLogs')
    },
    showModal(data) {
      document.getElementById('bid-modal-button')?.click()
      setTimeout(() => (this.selectedTableData = data), 100)
    },
    emptySelectedTableData() {
      this.selectedTableData = undefined
    },
    okHandler(data) {
      this.SET_LOADING(true)
      this.updateBiddingPriceList(data)
        .then(() => this.search())
        .then(() => this.SET_LOADING(false))
    },
    search() {
      this.SET_LOADING(true)
      return this.fetchBiddingPriceList(this.selectedProject)
        .then(res => (this.list = res.data))
        .then(() => this.SET_LOADING(false))
    }
  },
  created() {
    this.fetchProjects().then(() => this.selectOption(this.projectBodies[0]?.Seq))
  }
}
</script>
<style lang="scss">
/* #bid-price .modal-header .close {
  transform: none;
  margin: -0.6rem -1.2rem -0.6rem auto;
} */
</style>
